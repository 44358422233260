import { actionConfig } from "./ActionModal/config/actionConfigurations";
import actionModalConfig from "./ActionModal/config/actionModalConfig";
import chartModalApiConfig from "./ChartModal/config/chartModalApiConfig";
import dangerModalApiConfig from "./DangerModal/config/dangerModalApiConfig";
import mappingModalApiConfig from "./mappingModalApiConfig";
import pdfPreviewConfig from "./pdfPreviewConfig";
import settingsModalConfig from "./settingsModalConfig";
import showTicketModalConfig from "./showTicketModalConfig";
import infoModalConfig from "./InfoModal/config/infoModalConfig";
import ganttModalApiConfig from "./ganttModalApiConfig";

const modalConfig = {
  pdfPreviewConfig,
  dangerModalApiConfig,
  settingsModalConfig,
  showTicketModalConfig,
  infoModalConfig,
  actionModalConfig,
  mappingModalApiConfig,
  chartModalApiConfig,
  ganttModalApiConfig,
  actionConfig
};

export default modalConfig;