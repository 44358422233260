import { modifyPointCloud } from "api/timeline";
import { getPointCloudData } from "pages/unity/domain/getPointCloudData";
import colorDropdownConfig from "./colorDropdownConfig";

const projectDateConfig = {
  getPointCloudData,
  modifyPointCloud,
  colorDropdownConfig
};

export default projectDateConfig;