import { mapAssetsDocs } from "api/docs";
import { getAllUniqueFiles } from "api/fileManager";
import { getAllTags, getTagsByEquipmentsId, mapEquipmentTag } from "api/tags";

const mappingModalApiConfig = {
  mapAssetsDocs,
  getAllUniqueFiles,
  getAllTags,
  getTagsByEquipmentsId,
  mapEquipmentTag
};

export default mappingModalApiConfig;