import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import areaApiConfig from "./areaApiConfig";

const unityAreaConfig = {
  getTagData,
  getExpressionData,
  areaApiConfig
};

export default unityAreaConfig;