import { PageNotFound } from "processhub-components-lib";
import loginBackground from "assets/loginBackground.jpg";
import { Link } from "react-router-dom";

export default function Alarms() {
  return (
    <>
      <PageNotFound type={1} loginBG={loginBackground}>
        <Link to={"https://processhub.ai/"} style={{ color: 'darkblue', textDecoration: 'underline' }}>www.processhub.ai</Link>
      </PageNotFound>
    </>
  );
}
