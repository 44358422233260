import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import areaApiConfig from "./areaApiConfig";

const oneGaugeConfig = {
  getTagData,
  getExpressionData,
  areaApiConfig
};

export default oneGaugeConfig;