import { createNote, deleteNote, downloadFile, getNote, updateNote } from "api/unity";

const noteFormConfig = {
  getNote,
  updateNote,
  createNote,
  deleteNote,
  downloadFile
};

export default noteFormConfig;