import { getUserInfo, mapPicture, resetPassword, updateProfile, uploadPicture } from "api/user";

export const passwordConfig = {
  resetPassword
};

export const profileConfig = {
  getUserInfo,
  updateProfile,
  mapPicture,
  uploadPicture,
  passwordConfig: passwordConfig
};