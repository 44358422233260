import Layout from "components/layout";
import DashboardConfiguration from "components/shared/DashboardConfiguration/DashboardConfiguration";
import Alarms from "pages/alarms/Alarms";
import NewDashboard from "pages/NewDashboard";
import TicketsContainer from "pages/tickets/TicketsContainer";
import UnityContainer from "pages/unity";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import UsersContainer from "pages/users/UsersContainer";
import TempDashboard from "pages/Dashboard/temp";
import TagContainer from "pages/tags/TagContainer";
import { Modal } from "processhub-components-lib";
import modalConfig from "config/modal/modalConfig";
import allHooks from "hooks";
import { useState, useContext, useEffect } from "react";
import { ModalContext } from "hooks/ModalProvider";
import AllAssets from "pages/all-assets/AllAssets";
import ExpressionContainer from "pages/expressions/ExpressionContainer";
import Documents from "pages/documents/Documents";
import { PageNotFound } from "processhub-components-lib";
import loginBackground from "assets/loginBackground.jpg";
import { Link } from "react-router-dom";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>({
    action: "",
    value: null,
  });
  const [isMovementDisabled, setIsMovementDisabled] = useState(0);
  const { modalType, setModalType } = useContext(ModalContext);
  useEffect(() => {
    setSelectedTag({
      action: modalType,
      value: null,
    });
  }, [modalType]);
  const refresh = async () => { };

  useEffect(() => {
    if (selectedTag.action !== '') {
      setIsMovementDisabled(1);
    }
    else {
      setIsMovementDisabled(0);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (window.vuplex) {
      const message = JSON.stringify({ object: "__S:ReactInputBlocker__", type: "ReactInputBlocker", method: "SetBlockState", arguments: [isMovementDisabled] });
      window.vuplex.postMessage(message);
    }
  }, [isMovementDisabled]);

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<TempDashboard />} />
          <Route path="/3d-dashboard" element={<UnityContainer />} />
          <Route path="/dashboard" element={<NewDashboard />} />
          <Route path="/dashboard-configuration">
            <Route element={<DashboardConfiguration />}>
              <Route
                index
                element={<Navigate to="/dashboard-configuration/tags" />}
              />
              <Route path="tags" element={<TagContainer />} />
              <Route path="expressions" element={<ExpressionContainer />} />
              <Route path="documents" element={<Documents />} />
              <Route path="all-assets" element={<AllAssets />} />
            </Route>
          </Route>
          <Route path="/alarms" element={<Alarms />} />
          <Route path="/tickets" element={<TicketsContainer />} />
          <Route path="/users" element={<UsersContainer />} />
          <Route path="/3d-dashboard" element={<UnityContainer />} />
          <Route path="*" element={
            <PageNotFound type={0} loginBG={loginBackground}>
              <Link to={"https://processhub.ai/"} style={{ color: 'darkblue', textDecoration: 'underline' }}>www.processhub.ai</Link>
            </PageNotFound>
          }
          />
        </Route>
      </Routes>
      <Modal page="tags" setModalType={setModalType} isLoading={isLoading} refresh={refresh} selectedTag={selectedTag} setSelectedTag={setSelectedTag} modalConfig={modalConfig} hooks={allHooks} />
    </>
  );
}

export default App;
