import { useEffect } from "react";
import { ErrorPage } from "processhub-components-lib";
import ProgressMonitoringLogo from "components/shared/ProgressMonitoringLogo";

const LoginErrorPage = () => {

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }

  useEffect(() => {
    if (window.vuplex && location.pathname === '/3d-dashboard') {
      sendCanvasVisibility(0);
      return () => {
        sendCanvasVisibility(1);
      };
    }
    else {
      return () => {
        sendCanvasVisibility(0);
      };
    }
  }, [window.vuplex, location.pathname]);

  return (
    <ErrorPage>
      <ProgressMonitoringLogo variant="regular" />
    </ErrorPage>
  );
};

export default LoginErrorPage;