import { updateAlarm } from "api/alarm";
import { disableTag } from "api/tags";
import areaApiConfig from "config/charts/areaApiConfig";

const unityChartConfig = {
  updateAlarm,
  disableTag,
  areaApiConfig
};

export default unityChartConfig;