import kpiConfig from "config/charts/kpiConfig";
import largeConfig from "config/charts/largeConfig";
import oneGaugeConfig from "config/charts/oneGaugeConfig";
import unityAreaConfig from "config/charts/unityAreaConfig";
import unityColumnConfig from "config/charts/unityColumnConfig";
import unityGaugeBarConfig from "config/charts/unityGaugeBarConfig";
import unityGaugeConfig from "config/charts/unityGaugeConfig";
import allConfigurationApiConfig from "./allConfigurationApiConfig";

const tabBodyConfig = {
  largeConfig,
  kpiConfig,
  unityAreaConfig,
  unityGaugeConfig,
  unityColumnConfig,
  unityGaugeBarConfig,
  oneGaugeConfig,
  allConfigurationApiConfig
};

export default tabBodyConfig;