import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import moment from "moment";
import { PrettoSlider } from "./PrettoSlider";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Loader } from "processhub-components-lib";
import { ColorOptions, colorOptions } from "./colorOptions";
import { getPointCloudData } from "pages/unity/domain/getPointCloudData";
import useUnity, { pointCloud } from "hooks/UseUnity";
import { FiChevronsLeft, FiChevronsRight, FiHome, FiZoomIn, FiZoomOut } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import ScrollLine from "./ScrollDisplay";
import findClosestLessThan from "utilities/findClosestLessThan";

export default function DiscreteSlider() {
  const [pointCloudList, setpointCloudList] = useState<any>([]);
  const [value, setValue] = useState<any>(null);
  const {
    projectEnddate,
    projectStartdate,
    selectedValues,
    timelineLoading,
    setSelectedValues,
  } = useUnity();
  const today = moment(new Date());
  const [ZoomIn, setZoomIn] = useState<any>(false);
  const [ArraysforZoom, setArraysforZoom] = useState<any>(null);
  const [MainMarks, setMainMarks] = useState<any>(null);
  const [zoomScroll, setzoomScroll] = useState<number | null>(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [SelectedDate, setSelectedDate] = useState<any>(null);

  const [scanForId, setScanForId] = useState<number | null>(null);

  useEffect(() => {
    if (value > 0) {
      localStorage.setItem('timelineDatePosition', value);
    }
  }, [value]);

  useEffect(() => {
    const loadValueFromStorage = () => {
      const storedValue = localStorage.getItem('timelineDatePosition');
      if (storedValue) {
        setValue(Number(storedValue));
      }
    };
    if (typeof window !== 'undefined') {
      setTimeout(loadValueFromStorage, 1000);
    }
  }, []);

  useEffect(() => {
    const marks = Array.from({ length: 101 }).map((_, index) => {
      const startDate = moment(projectStartdate);
      const endDate = moment(projectEnddate);
      const diffDays = endDate.diff(startDate, "days");
      const currentDate = startDate
        .clone()
        .add(Math.round((index / 100) * diffDays), "days");

      return {
        date: currentDate.format("DD/MM/YYYY"),
        value: index,
        label:
          index % 10 === 0 || index === 0 || index === 100
            ? currentDate.format("DD/MM/YYYY")
            : null,
      };
    });
    setMainMarks(marks);
  }, []);

  function addMessageListener() {
    setValue(Number(localStorage.getItem('timelineDatePosition')));
    window.vuplex.addEventListener("message", async function (event: { data: any }) {
      const tjson = event.data;
      const parsedInfo = JSON.parse(tjson);
      if (parsedInfo.methodName === 'GetColorByScanID') {
        const scanIdForColor = JSON.parse(parsedInfo.message);
        setScanForId(Number(scanIdForColor));
      }
    });
  }

  useEffect(() => {
    if (window.vuplex) {
      setTimeout(addMessageListener, 1000); // adjust the delay if needed
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }
    return () => {
      window.removeEventListener('vuplexready', addMessageListener); // cleanup listener
    };
  }, []);
  

  useEffect(() => {
    if (scanForId !== null) {
      const currentPointCloud = pointCloudList.find((pointCloudItem: any) => {
        return pointCloudItem.value.id === scanForId;
      });
      const reply = { scanId: scanForId, color: colorOptions[currentPointCloud.value.attribute].color };

      window.vuplex.postMessage(JSON.stringify({
        object: '__ScansManager__',
        type: 'Scans.ScansManager',
        method: 'SetScanColor',
        arguments: [JSON.stringify(reply)]
      }));
    }
  }, [scanForId, value]);

  const findValueByDate = (marks: any, targetDate: Date) => {
    const targetFormattedDate = moment(targetDate).format("DD/MM/YYYY");
    const foundElement = marks.find(
      (mark: any) => mark.date == targetFormattedDate
    );
    return foundElement ? foundElement.value : null;
  };

  const findValueByDateTimeline = (marks: any, targetDate: Date, ispointcloud: boolean) => {
    const targetFormattedDate = moment(targetDate).format("DD/MM/YYYY");

    if (MainMarks && MainMarks.length > 0) {
      const lastMarkDate = moment(MainMarks[MainMarks.length - 1].date, "DD/MM/YYYY");
      if (moment(targetFormattedDate, "DD/MM/YYYY").isAfter(lastMarkDate)) {
        return null;
      }
      let foundElement = MainMarks.find(
        (mark: any) => mark.date == targetFormattedDate
      );
      if (!foundElement) {
        let nearestElement = null;
        for (let i = MainMarks.length - 1; i >= 0; i--) {
          if (moment(marks[i].date, "DD/MM/YYYY").isBefore(moment(targetFormattedDate, "DD/MM/YYYY"))) {
            nearestElement = marks[i];
            break;
          }
        }
        foundElement = nearestElement;
      }

      return foundElement ? foundElement.value : null;
    }

    return null;
  };

  const handleChange = (event: Event, newValue: any) => {
    let todaymark: any = findValueByDate(MainMarks, today.toDate());
    if (!todaymark) {
      for (let i = MainMarks.length - 1; i >= 0; i--) {
        if (moment(MainMarks[i].date, "DD/MM/YYYY").isBefore(today)) {
          todaymark = MainMarks[i].value;
        } else {
          todaymark = null;
        }
      }
    }
    if (todaymark && newValue >= todaymark) {
      setValue(todaymark);
    } else {
      setValue(newValue);
    }
  };


  useEffect(() => {
    const pointcloudlist: any = [];
    if (selectedValues && selectedValues.length > 0) {
      selectedValues.map((value: pointCloud) => {
        const dateindexvalue = findValueByDateTimeline(
          MainMarks,
          new Date(value.scannedAt),
          true
        );
        if (dateindexvalue) {
          pointcloudlist.push({ dateindexvalue: dateindexvalue, value: value });
        }
      });
      setpointCloudList(pointcloudlist);
    }
  }, [selectedValues, value, MainMarks, ZoomIn]);

  useEffect(() => {
    if (MainMarks && MainMarks.length > 0) {
      let scanIds = [];
      const curr = MainMarks.filter((mark: any) => mark.value === value);
      let currdate: any = [];
      if (curr.length > 0) {
        currdate = curr[0].date;
      }
      const allPastDates = pointCloudList.filter((item: any) => new Date(item.value.scannedAt) < moment(currdate, 'DD/MM/YYYY').toDate());
      const currentDatePointCloud = allPastDates[allPastDates.length - 1];
      if (currentDatePointCloud !== undefined) {
        const currentItems = pointCloudList.filter((date: any) => date.dateindexvalue === currentDatePointCloud.dateindexvalue);
        if (currentItems && currentItems.length > 0) {
          scanIds = currentItems.map((item: any) => item.value.id);
        }
      }

      if (window.vuplex) {
        window.vuplex.postMessage(
          JSON.stringify({
            object: "__ScansManager__",
            type: "Scans.ScansManager",
            method: "SetScanIDs",
            arguments: [JSON.stringify(scanIds)],
          })
        );
      }
    }
  }, [value, selectedValues, MainMarks]);

  useEffect(() => {
    getPointCloudData(setSelectedValues);
  }, []);


  const handleReload = () => {
    const today = findValueByDateTimeline(MainMarks, new Date(), false);
    if (today) {
      setValue(today);
    } else {
      setValue(100);
    }
  };

  const zoomIn = () => {
    setZoomIn(true);
    let z = zoomLevel;
    z = z + 1;
    setZoomLevel(z);
  };

  const zoomOut = () => {
    let z = zoomLevel;
    if (z === 0) {
      setZoomLevel(0);
      setZoomIn(false);
      setzoomScroll(null);
      setArraysforZoom(null);
    }
    else {
      z = z - 1;
      setZoomLevel(z);
      setZoomIn(true);
    }
  };

  const resetZoom = () => {
    setZoomLevel(0);
    setZoomIn(false);
  };

  useEffect(() => {
    if (MainMarks) {
      const curr = MainMarks.find((item: any) => item.value === value);
      if (curr) {
        setSelectedDate(curr.date);
      }
    }
  }, [value, MainMarks]);


  useEffect(() => {
    if (zoomLevel === 0) {
      const marks = Array.from({ length: 101 }).map((_, index) => {
        const startDate = moment(projectStartdate);
        const endDate = moment(projectEnddate);
        const diffDays = endDate.diff(startDate, "days");
        const currentDate = startDate
          .clone()
          .add(Math.round((index / 100) * diffDays), "days");

        return {
          date: currentDate.format("DD/MM/YYYY"),
          value: index,
          label:
            index % 10 === 0 || index === 0 || index === 100
              ? currentDate.format("DD/MM/YYYY")
              : null,
        };
      });

      setMainMarks(marks);
      setzoomScroll(null);
      setArraysforZoom(null);
      if (SelectedDate) {
        const index = findValueByDate(marks, moment(SelectedDate, 'DD/MM/YYYY').toDate());
        setValue(index);
      }

    }
    else {
      const totalPoints = Math.ceil((zoomLevel * 100 + 101) / 101) * 101;
      const newMarks = Array.from({ length: totalPoints }).map((_, index) => {
        const startDate = moment(projectStartdate);
        const endDate = moment(projectEnddate);
        const diffDays = endDate.diff(startDate, "days");
        const currentDate = startDate
          .clone()
          .add(Math.round((index / (totalPoints - 1)) * diffDays), "days");

        return {
          date: currentDate.format("DD/MM/YYYY"),
          value: index,
          label:
            index % 10 === 0 || index === 0 || index === totalPoints - 1
              ? currentDate.format("DD/MM/YYYY")
              : null,
        };
      });
      const splitArraySize = Math.round(newMarks.length / (zoomLevel + 1));
      let newArrays = [];
      for (let i = 0; i <= zoomLevel; i++) {
        const start = i * splitArraySize;
        const end = i === zoomLevel ? newMarks.length : start + splitArraySize;
        newArrays.push(newMarks.slice(start, end));
      }
      newArrays = newArrays.map((arr: any, ind: any) => {
        return arr.map((item: any, index: any) => {
          return {
            ...item,
            value: index,
            label:
              index % 10 === 0 || index === 0 || index === 100
                ? item.date
                : null,
          };
        });
      });
      setArraysforZoom(newArrays);
      // setMainMarks(newArrays[0]);
      // setzoomScroll(0);
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (ArraysforZoom && ArraysforZoom.length > 0) {
      ArraysforZoom.forEach((marks: any, index: any) => {
        marks.forEach((mark: any, valueIndex: any) => {
          if (mark.date === SelectedDate) {
            setMainMarks(ArraysforZoom[index]);
            setzoomScroll(index);
            setValue(valueIndex);
          }
        });
      });
    }
  }, [ArraysforZoom]);


  const handleSwitchRight = () => {
    let z = zoomScroll;
    if (z != null) {
      if (z >= ArraysforZoom.length - 1) {
        z = ArraysforZoom.length - 1;
      }
      else {
        z = z + 1;
      }
    }
    setzoomScroll(z);
  };
  const handleSwitchLeft = () => {
    let z = zoomScroll;
    if (z != null) {
      if (z <= 0) {
        z = 0;
      }
      else {
        z = z - 1;
      }
    }
    setzoomScroll(z);
  };

  useEffect(() => {
    if (zoomScroll != null && zoomScroll < ArraysforZoom.length) {
      setMainMarks(ArraysforZoom[zoomScroll]);
    }
  }, [zoomScroll]);

  const resetMarkColors = () => {
    const markElements = document.querySelectorAll(".MuiSlider-mark");

    markElements.forEach((element) => {
      element.classList.remove('pointCloudLabelledMarkMulti');
      (Object.keys(colorOptions) as Array<keyof ColorOptions>).forEach((color) => {
        element.classList.remove(colorOptions[color].unlabelled);
        element.classList.remove(colorOptions[color].labelled);
      });
    });
  };


  useEffect(() => {
    resetMarkColors();
    const markElements = document.querySelectorAll(".MuiSlider-mark");
    if (pointCloudList && pointCloudList.length > 0 && MainMarks) {
      markElements.forEach((element, index) => {
        const markValue = MainMarks[index]?.value;
        const itemsForMark = pointCloudList.filter((item: any) => item.dateindexvalue === markValue);
        if (itemsForMark.length > 1) {
          element.classList.add('pointCloudLabelledMarkMulti');
          element.classList.add('markedPoint');
        } else if (itemsForMark.length === 1) {
          const item = itemsForMark[0];
          const sparse = item.value.details.find((item: any) => item.type === 'Sparse');
          if (sparse && sparse.haveData) {
            element.classList.add('noPointCloud');
          }
          const color: keyof ColorOptions = item?.value?.attribute
            ? item?.value?.attribute
            : null;
          if (color) {
            if (MainMarks[index]?.label === null) {
              element.classList.add(colorOptions[color].unlabelled);
              element.classList.add('markedPoint');
            } else {
              element.classList.add(colorOptions[color].labelled);
              element.classList.add('markedPoint');
            }
          }
        }
      });
    }
  }, [MainMarks, pointCloudList, ZoomIn, zoomScroll, zoomLevel, projectEnddate, projectStartdate]);

  useEffect(() => {
    if (value !== null) {
      const markedPoints = document.querySelectorAll(".markedPoint");
      const markedPointsArray = Array.from(markedPoints);
      const markedPointsValues = markedPointsArray.map(point => Number(point.getAttribute("data-index")));

      if (markedPoints.length > 0) {
        const closestItem = findClosestLessThan(value, markedPointsValues);

        markedPoints.forEach((point) => {
          const pointValue = Number(point.getAttribute("data-index"));

          if (pointValue === closestItem) {
            point.classList.add('outline', 'outline-white', 'animate-glow');
          } else {
            point.classList.remove('outline', 'outline-white', 'animate-glow');
          }
        });
      }
    }
  }, [value, MainMarks, selectedValues]);
  
  const markLabel = (mark: number) => {
    if (MainMarks) {
      const markIndex = MainMarks.findIndex((m: any) => m.value === mark);
      if (markIndex !== -1) {
        return MainMarks[markIndex].date;
      }
    }
    return "";
  };


  if (timelineLoading)
    return (
      <div className="w-full h-full grid place-content-center min-h-[100px]">
        <Loader />
      </div>
    );

  return (
    <Box sx={{ width: 1100, marginTop: window.vuplex ? "" : "", position: "relative" }}>
      <div className="bg-[rgb(0,0,0,0.6)] rounded-t-sm rounded-l-0 flex w-[6.5rem] absolute -top-[1.22rem] left-[30rem] justify-center p-1 hover:z-50 ">
        <FiZoomIn className="p-1 cursor-pointer" color="white" size={27} onClick={zoomIn} />
        <FiZoomOut className={classNames("p-1 cursor-pointer", zoomLevel === 0 ? "opacity-20" : "")} color="white" size={27} onClick={zoomOut} />
        <ReloadIcon
          color="white"
          height={27}
          width={27}
          className="p-1 cursor-pointer"
          onClick={handleReload}
        ></ReloadIcon>
        <FiHome className="p-1 cursor-pointer" size={27} onClick={resetZoom} />
      </div>
      {ZoomIn && <FiChevronsLeft className="absolute top-9 z-50 -left-12 cursor-pointer" size={40} onClick={handleSwitchLeft} />}
      {ZoomIn && <FiChevronsRight className="absolute top-9 z-40 -right-12 cursor-pointer" size={40} onClick={handleSwitchRight} />}
      <PrettoSlider
        aria-label="Timeline"
        getAriaValueText={(value: number) => `${value}`}
        onChange={handleChange}
        defaultValue={50}
        marks={MainMarks}
        min={0}
        max={100}
        valueLabelDisplay="auto"
        valueLabelFormat={markLabel}
        style={{
          color: "black",
          boxShadow: 'none',
        }}
        value={value}
        sx={{
          '& .MuiSlider-thumb': {
            boxShadow: 'none !important',
            '&:hover': {
              boxShadow: 'none !important',
            },
            '&.Mui-focusVisible': {
              boxShadow: 'none !important',
            },
            '&::before': {
              content: '""',
              display: 'block',
              boxShadow: 'none !important',
            },
            '&::after': {
              content: '""',
              display: 'block',
              boxShadow: 'none !important',
            },
          },
          '& .MuiSlider-mark': {
            boxShadow: 'none !important',
            opacity: 1
          },
        }}
      />
      {ZoomIn && zoomScroll != null && ArraysforZoom && <div className="opacity-60"><ScrollLine currentSegment={zoomScroll} totalSegments={ArraysforZoom.length} /></div>}
    </Box>
  );
}
