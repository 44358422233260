import expressionsConfig from "config/expressionsConfig";
import fileUploaderApiConfig from "config/fileUploaderApiConfig";
import tagsFileUploaderApiConfig from "../../tagsFileUploaderApiConfig";
import assetsSelectsConfig from "config/assetsSelectsConfig";
import actionApiConfig from "./actionApiConfig";

const actionModalConfig = {
  expressionsConfig,
  fileUploaderApiConfig,
  tagsFileUploaderApiConfig,
  assetsSelectsConfig,
  actionApiConfig
};

export default actionModalConfig;