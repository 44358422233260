import { addProject, getAllProjects, getAllTasks } from "api/projectPlan";
import fileUploaderApiConfig from "config/fileUploaderApiConfig";

const ganttModalApiConfig = {
  addProject,
  getAllProjects,
  getAllTasks,
  fileUploaderApiConfig
};

export default ganttModalApiConfig;