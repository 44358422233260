import { TabsProvider } from "hooks/dynamicWidgets/useTabs";
import { WidgetsProvider } from "hooks/dynamicWidgets/useWidgets";
import { WidgetsDataProvider } from "hooks/dynamicWidgets/useWidgetsData";
import allHooks from "hooks";

import { WidgetGrid } from "processhub-components-lib";
import tabBodyConfig from "config/dashboard/tabBodyConfig";

export default function DynamicWidgets({ type }: { type: any }) {
  return (
    <WidgetsProvider>
      <WidgetsDataProvider>
        <TabsProvider>
          <WidgetGrid type={type} hooks={allHooks} tabBodyConfig={tabBodyConfig} />
        </TabsProvider>
      </WidgetsDataProvider>
    </WidgetsProvider>
  );
}
