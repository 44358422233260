import { processDroneImages } from "api/pointCloud";
import fileExplorerConfig from "config/fileExplorerConfig";
import fileUploaderApiConfig from "config/fileUploaderApiConfig";
import projectDateConfig from "config/projectDateConfig";

const settingsModalConfig = {
  processDroneImages,
  fileUploaderApiConfig,
  fileExplorerConfig,
  projectDateConfig
};

export default settingsModalConfig;