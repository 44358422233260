import { deleteAlarm } from "api/alarm";
import { mapAssetsDocs } from "api/docs";
import { deleteEquipment } from "api/equipments";
import { deleteExpression } from "api/expression";
import { deleteExplorerFiles, deleteFile } from "api/fileManager";
import { deleteTag, resetTag } from "api/tags";
import { deleteTicket } from "api/tickets";
import { deleteTrain } from "api/trains";
import { deleteUnit } from "api/units";
import { deleteWidget } from "api/unity";

const dangerModalApiConfig = {
  deleteExpression,
  deleteExplorerFiles,
  deleteTag,
  resetTag,
  deleteFile,
  deleteAlarm,
  deleteTicket,
  deleteEquipment,
  deleteTrain,
  deleteUnit,
  deleteWidget,
  mapAssetsDocs
};

export default dangerModalApiConfig;