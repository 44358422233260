import { getEquipmentsByUnitId } from "api/equipments";
import { getExpressionByEquipment, getExpressionById, getExpressionByUnit } from "api/expression";
import { getTagsByEquipmentsId } from "api/tags";
import { createWidget, updateWidget } from "api/unity";
import modalConfig from "config/modal/modalConfig";

const unityWidgetConfig = {
  getExpressionByEquipment,
  getExpressionByUnit,
  getExpressionById,
  getTagsByEquipmentsId,
  getEquipmentsByUnitId,
  createWidget,
  updateWidget,
  modalConfig
};

export default unityWidgetConfig;