import { useEffect, useState } from 'react';
import classNames from "utilities/ClassNames";
import { FiChevronDown } from "react-icons/fi";

const HeaderMenu = ({ buttonsConfig }: any) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isScaled, setIsScaled] = useState(window.innerWidth < 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsScaled(window.innerWidth < 1300);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="relative">
      {isScaled && <button onClick={toggleMenu}>
        <FiChevronDown />
      </button>}
      <div className={classNames(menuVisible ? 'menu-enter' : 'menu-exit', isScaled ? "absolute top-8 bg-dark p-2 rounded" : "", "flex items-center gap-4")}>
        {buttonsConfig.map(({ shown, name, onClick, type, Icon, activeClass, inactiveClass }: any, index: any) => (
          <button
            key={index}
            className={classNames(shown ? activeClass : inactiveClass, "cursor-pointer relative h-[22px] w-[22px] group")}
            onClick={onClick}
          >
            <Icon className={classNames(type === 'component' ? "absolute top-0 left-0" : "w-full h-full")} />
            <span className="absolute left-0 -bottom-5 opacity-0 group-hover:opacity-80 transition-opacity text-white">{name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default HeaderMenu;
