import { getEquipmentsByUnitId } from "api/equipments";
import { createBubble, deleteBubble, getBubble, updateBubble } from "api/unity";

const bubbleConfig = {
  getEquipmentsByUnitId,
  getBubble,
  updateBubble,
  createBubble,
  deleteBubble
};

export default bubbleConfig;