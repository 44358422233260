import { getMappedAssetDocs, mapAssetDocs } from "api/docs";
import { getFile } from "api/fileManager";
import { createTicket } from "api/tickets";
import { getTrains } from "api/trains";
import areaApiConfig from "config/charts/areaApiConfig";
import modalConfig from "config/modal/modalConfig";
import pdfPreviewConfig from "config/modal/pdfPreviewConfig";
import unityChartConfig from "./unityChartConfig";


const unityChartsContainerConfig = {
  createTicket,
  getFile,
  getMappedAssetDocs,
  mapAssetDocs,
  getTrains,
  pdfPreviewConfig,
  modalConfig,
  areaApiConfig,
  unityChartConfig
};

export default unityChartsContainerConfig;