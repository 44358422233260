import { AiOutlineFieldTime } from "react-icons/ai";
import { BsTags } from "react-icons/bs";
import { FiSettings, FiUsers } from "react-icons/fi";
import { RxDashboard, RxStopwatch, RxIdCard, RxGlobe } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";

export const navLinks = (isScaled: any) => {
  return (
    [
      {
        to: "/dashboard",
        icon: <RxDashboard size={isScaled ? 18 : 20} />,
        text: "Dashboard",
      },
      {
        to: "/dashboard",
        icon: <FiSettings size={isScaled ? 18 : 20} />,
        text: "Dashboard Configuration",
      },
      {
        to: "/3d-dashboard",
        icon: <TbBrandUnity size={isScaled ? 18 : 20} />,
        text: "3D Plant",
      },
      {
        to: "/dashboard-configuration",
        icon: <BsTags size={isScaled ? 18 : 20} />,
        text: "Tags",
      },
      {
        to: "/alarms",
        icon: <RxStopwatch size={isScaled ? 18 : 20} />,
        text: "Alarms",
      },
      {
        to: "/tickets",
        icon: <RxIdCard size={isScaled ? 18 : 20} />,
        text: "Tickets",
      },
      {
        to: "/users",
        icon: <FiUsers size={isScaled ? 18 : 20} />,
        text: "Users",
      },
      {
        to: "/logs",
        icon: <AiOutlineFieldTime size={isScaled ? 18 : 20} />,
        text: "Logs",
      },
      {
        to: `${process.env.REACT_APP_COMMON_APP}`,
        icon: <RxGlobe size={isScaled ? 18 : 20} />,
        text: "Back to Saudi Map",
      },
    ]

  );
};